import { clarity } from "clarity-js";

export function useClarity() {
  async function initClarity() {
    clarity.consent();

    clarity.start({ 
      projectId: process.env.VUE_CLARITY_ID,
      upload: "https://m.clarity.ms/collect",
      track: true,
      content: true,
    });
    
  }

  return {
    initClarity,
  };
}