import GlobalSettingsService from "@/services/resources/GlobalSettingsService";

export default {
  setIsMobile: ({ commit }, payload) => {
    commit("setIsMobile", payload);
  },
  fetchGlobalSettings: async ({ commit , getters}, keys) => {
    const service = GlobalSettingsService.build();
    try {
      let url = "?keys=";
      if (Array.isArray(keys)) {
        url += keys.join(",");
      }
      const request = await service.get(url);
      if (request) {
        await commit("updateGlobalSettings", request);      
      }
    } catch (error) {
      console.error(error);
    }
  },

  validatorSettings: ({ getters }, value) => {
    const existFlag = getters["getGlobalSettings"].find(
      (flag) => flag.key === value
    );    
    if(existFlag && existFlag.value){
      const valueFlag = existFlag.value === "ENABLED";

      return valueFlag;
    }
    return false
  },

  isFlagEnabled: async ({ dispatch }, value) => {
    const valueSetting = await dispatch("validatorSettings", value);
    
    if (!valueSetting) {
      await dispatch("fetchGlobalSettings",[value]);    
      return await dispatch("validatorSettings", value)
    }

    return valueSetting;
  },
};
