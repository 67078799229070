import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import { clarity } from 'clarity-js';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err
    });
}

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes
});

router.afterEach((to) => {
    clarity.event('pageView', {
        page: to.fullPath
    })
})

export default router;